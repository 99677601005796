import classNames from "classnames";
import React from "react";
import { pageCss, aboutText } from "../components/Constant";
import { Icons } from "../components/Icons";

const Gallery = () => {
  return (
    <div className={classNames(" bg-[#f1f1f1] h-[90vh]", pageCss.container)}>
      <section className={pageCss.section}>
        <div className={pageCss.header}>
          <h2 className={classNames("!text-[#c21e56]", pageCss.heading)}>
            About Ashok tekam
          </h2>
        </div>
        <div className={classNames("sm:mt-3", pageCss.body)}>
          <div className="gap-x-1 grid grid-cols-4 sm:flex sm:flex-col">
            <div className="dp col-span-1 sm:flex sm:self-center sm:mb-3">
              <img
                src="./Ashok-dp.jpg"
                className=" rounded-full bg-inherit w-[400px] sm:w-[300px]"
                alt="image loading..."
              />
            </div>
            <div className="col-span-2 self-center max-h-[500px] overflow-y-scroll">
              <p className="overflow-y-scroll sm:text-xs">{aboutText}</p>
              <span className="justify-end gap-x-2 flex mt-4 text-sm">
                <p className="flex gap-x-1">
                  अशोक टेकाम{" "}
                  <span>
                  <Icons icon="Pencil" fill="#000" className='mt-1' width="1em" />
                  </span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Gallery;
