import React from "react";
import ImageCarousel from "./components/ImageCarousel";
import Journey from "./components/Journey";
import PhotoGallery from "./components/PhotoGallery";
import SocialMedia from "./components/SocialMedia"
const Home = () => {
  return (
    <div className="h-fit max-h-fit">
      <ImageCarousel />
      <Journey/>
      <PhotoGallery/>
      <SocialMedia />
    </div>
  );
};
export default Home;
