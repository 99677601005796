import React from "react";
import { journeyData, pageCss } from "./Constant";
import ImageFrame from "./ImageFrame";
import classNames from "classnames";

const Journey = () => {
  return (
    <div className={classNames('bg-[#252d52]', pageCss.container)}>
      <section className={pageCss.section}>
        <div className={pageCss.header}>
          <h2 className={classNames('text-white', pageCss.heading)}>
            Journey
          </h2>
        </div>
        <div className={classNames('sm:flex-wrap justify-around xmd:flex-wrap !sm:px-1',pageCss.body)}>
          {journeyData.map((item, val) => (
            <div
              key={val}
              className={classNames('flex max-w-[750px] sm:max-w-[380px]  py-2 flex-row border-white xxsm:border-b sm:border-r-0', {'border-r ': journeyData.length -1 !== val})}
            >
              <div className="flex flex-col p-8 md:p-6 xmd:p-5 sm:p-4 xsm:p-3">
                <ImageFrame image={item.imageSource} />
                <p className="heading text-[#b6bfe6] font-semibold text-3 mt-6 mb-2">{item.Heading}</p>
                <p className="details text-white text-6 font-semibold mb-2">{item.year}</p>
                <p className="rightBorder text-[#8990af] max-h-[700px] overflow-auto">{item.matter}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
export default Journey;
