import React from "react";
import { pGallery, pageCss } from "./Constant";
import classNames from "classnames";
import { ImageFrameV2 } from "./ImageFrame";

const PhotoGallery = () => {
  return (
    <div className={classNames("bg-[#dddddd]", pageCss.container)}>
      <section className={pageCss.section}>
        <div className={pageCss.header}>
          <h2 className={classNames("!text-black", pageCss.heading)}>Photo Gallery</h2>
        </div>
        <div className={classNames('overflow-scroll', pageCss.body)}>
            {pGallery?.map((item, index)=>(
                <div className="" key={index}><ImageFrameV2 item={item} /></div>
            ))}
        </div>
      </section>
    </div>
  );
};
export default PhotoGallery;
