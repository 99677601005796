import React from "react";
import { footerContent, footerContent2 } from "./Constant";
import "./footer.css";

const Footer = ({ Ref }) => {
  return (
    <footer class="footer" ref={Ref}>
      <div class="container row">
        <div class="footer-col">
          <h4>Social Media</h4>
          <ul>
            {footerContent.map((item, val) => (
              <li>
                <a href="#">
                  <div className="gap-x-3 flex items-center">
                    {item.icon}
                    <span className="text-left sm:text-sm">{item.text}</span>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div class="footer-col">
          <h4>Reach out to us </h4>
          <ul>
            {footerContent2.map((item, val) => (
              <li>
                <a href="#">
                  <div className="gap-x-3 flex items-center">
                    {item.icon}
                    <span className="text-left sm:text-sm">{item.text}</span>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div class="footer-col self-center">
          <button
            onClick={() => console.log("link do")}
            className="px-6 py-2 sm:px-4 sm:py-1 sm:text-sm text-white  rounded-md bg-[#fb923c]"
          >
            Join us
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
