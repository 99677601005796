import React from "react";
import { Icons } from "./Icons";

const ImageFrame = (data) => {
  return (
    <div className="">
      <img
        src={data.image}
        className="rounded border-white border-2 p-1 bg-white max-w-350px max-h-350px"
        alt={data.image || "Image loading..."}
      />
    </div>
  );
};

export default ImageFrame;

export const ImageFrameV2 = ({ item }) => {
  return (
    <div className="my-10 sm:my-2 w-auto">
      <div className="flex flex-col px-6 mx-5 bg-white pb-2 pt-4 w-[450px] sm:w-[360px] sm-px-2 sm:mx-1">
        <div className="flex flex-col content-start">
          <img
            className="h-[350px] object-cover border border-black rounded-sm"
            src={item.image}
            alt={item.image || "image loading..."}
          />
          <section className="max-h-[350px] items-start flex flex-col mt-[20px]">
            <span className="text-black font-semibold text-3 line-clamp-1">
              {item.heading}
            </span>
            <span className="text-[#666] text-base mt-2">{item.date}</span>
            <span className="text-left max-h-[200px] text-sm mt-4 mb-8 text-[#6b6b6b] line-clamp-4 ali">
              {item.content}
            </span>
          </section>
        </div>
        <a href="./gallery">
          <div className=" flex !items-center gap-x-2 text-base pt-1 justify-end mt-4 border-t border-gray-500 text-[#dd153d]">
            Read more
            <Icons icon="Forward" fill="#dd153d" className='mt-1' width="1em" />
          </div>
        </a>
      </div>
    </div>
  );
};

