import React from "react";
const IconList = [
  {
    name: "Cross",
    paths: [
      "M1014.662 822.66c-0.004-0.004-0.008-0.008-0.012-0.010l-310.644-310.65 310.644-310.65c0.004-0.004 0.008-0.006 0.012-0.010 3.344-3.346 5.762-7.254 7.312-11.416 4.246-11.376 1.824-24.682-7.324-33.83l-146.746-146.746c-9.148-9.146-22.45-11.566-33.828-7.32-4.16 1.55-8.070 3.968-11.418 7.31 0 0.004-0.004 0.006-0.008 0.010l-310.648 310.652-310.648-310.65c-0.004-0.004-0.006-0.006-0.010-0.010-3.346-3.342-7.254-5.76-11.414-7.31-11.38-4.248-24.682-1.826-33.83 7.32l-146.748 146.748c-9.148 9.148-11.568 22.452-7.322 33.828 1.552 4.16 3.97 8.072 7.312 11.416 0.004 0.002 0.006 0.006 0.010 0.010l310.65 310.648-310.65 310.652c-0.002 0.004-0.006 0.006-0.008 0.010-3.342 3.346-5.76 7.254-7.314 11.414-4.248 11.376-1.826 24.682 7.322 33.83l146.748 146.746c9.15 9.148 22.452 11.568 33.83 7.322 4.16-1.552 8.070-3.97 11.416-7.312 0.002-0.004 0.006-0.006 0.010-0.010l310.648-310.65 310.648 310.65c0.004 0.002 0.008 0.006 0.012 0.008 3.348 3.344 7.254 5.762 11.414 7.314 11.378 4.246 24.684 1.826 33.828-7.322l146.746-146.748c9.148-9.148 11.57-22.454 7.324-33.83-1.552-4.16-3.97-8.068-7.314-11.414z",
    ],
  },
  {
    name: "Hamburger",
    paths: [
      "M0 192h896v192h-896v-192zM0 448h896v192h-896v-192zM0 704h896v192h-896v-192z",
      "M992 448l192 192 192-192z",
    ],
  },
  {
    name: "Twitter",
    paths: [
      "M778.411 96h141.141l-308.352 352.427 362.752 479.573h-284.032l-222.464-290.859-254.549 290.859h-141.227l329.813-376.96-347.989-455.040h291.243l201.088 265.856zM728.875 843.52h78.208l-504.832-667.477h-83.925z",
    ],
  },
  {
    name: "Facebook",
    paths: [
      "M608 192h160v-192h-160c-123.514 0-224 100.486-224 224v96h-128v192h128v512h192v-512h160l32-192h-192v-96c0-17.346 14.654-32 32-32z",
    ],
  },
  {
    name: "Whatsapp",
    paths: [
      "M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z",
    ],
  },
  {
    name: "Gmail",
    paths: [
      "M1024 192v640c0 36.267-27.733 64-64 64h-64v-580.821l-384 275.755-384-275.755v580.821h-64c-36.309 0-64-27.733-64-64v-640c0-18.133 6.912-34.133 18.389-45.568 11.477-11.605 27.52-18.432 45.611-18.432h21.333l426.667 309.333 426.667-309.333h21.333c18.133 0 34.133 6.912 45.611 18.432 11.52 11.435 18.389 27.435 18.389 45.568z",
    ],
  },
  {
    name: "Instagram",
    paths: [
      "M512 92.2c136.8 0 153 0.6 206.8 3 50 2.2 77 10.6 95 17.6 23.8 9.2 41 20.4 58.8 38.2 18 18 29 35 38.4 58.8 7 18 15.4 45.2 17.6 95 2.4 54 3 70.2 3 206.8s-0.6 153-3 206.8c-2.2 50-10.6 77-17.6 95-9.2 23.8-20.4 41-38.2 58.8-18 18-35 29-58.8 38.4-18 7-45.2 15.4-95 17.6-54 2.4-70.2 3-206.8 3s-153-0.6-206.8-3c-50-2.2-77-10.6-95-17.6-23.8-9.2-41-20.4-58.8-38.2-18-18-29-35-38.4-58.8-7-18-15.4-45.2-17.6-95-2.4-54-3-70.2-3-206.8s0.6-153 3-206.8c2.2-50 10.6-77 17.6-95 9.2-23.8 20.4-41 38.2-58.8 18-18 35-29 58.8-38.4 18-7 45.2-15.4 95-17.6 53.8-2.4 70-3 206.8-3zM512 0c-139 0-156.4 0.6-211 3-54.4 2.4-91.8 11.2-124.2 23.8-33.8 13.2-62.4 30.6-90.8 59.2-28.6 28.4-46 57-59.2 90.6-12.6 32.6-21.4 69.8-23.8 124.2-2.4 54.8-3 72.2-3 211.2s0.6 156.4 3 211c2.4 54.4 11.2 91.8 23.8 124.2 13.2 33.8 30.6 62.4 59.2 90.8 28.4 28.4 57 46 90.6 59 32.6 12.6 69.8 21.4 124.2 23.8 54.6 2.4 72 3 211 3s156.4-0.6 211-3c54.4-2.4 91.8-11.2 124.2-23.8 33.6-13 62.2-30.6 90.6-59s46-57 59-90.6c12.6-32.6 21.4-69.8 23.8-124.2 2.4-54.6 3-72 3-211s-0.6-156.4-3-211c-2.4-54.4-11.2-91.8-23.8-124.2-12.6-34-30-62.6-58.6-91-28.4-28.4-57-46-90.6-59-32.6-12.6-69.8-21.4-124.2-23.8-54.8-2.6-72.2-3.2-211.2-3.2v0z",
      "M512 249c-145.2 0-263 117.8-263 263s117.8 263 263 263 263-117.8 263-263c0-145.2-117.8-263-263-263zM512 682.6c-94.2 0-170.6-76.4-170.6-170.6s76.4-170.6 170.6-170.6c94.2 0 170.6 76.4 170.6 170.6s-76.4 170.6-170.6 170.6z",
      "M846.8 238.6c0 33.91-27.49 61.4-61.4 61.4s-61.4-27.49-61.4-61.4c0-33.91 27.49-61.4 61.4-61.4s61.4 27.49 61.4 61.4z",
    ],
  },
  {
    name: "Forward",
    paths: [
      "M25.714 943.429c-14.286 14.286-25.714 9.143-25.714-10.857v-841.143c0-20 11.429-25.143 25.714-10.857l405.714 405.714c3.429 3.429 5.714 6.857 7.429 10.857v-405.714c0-20 11.429-25.143 25.714-10.857l405.714 405.714c14.286 14.286 14.286 37.143 0 51.429l-405.714 405.714c-14.286 14.286-25.714 9.143-25.714-10.857v-405.714c-1.714 4-4 7.429-7.429 10.857z",
    ],
  },
  {
    name: "Backward",
    paths: ["M576 160v320l320-320v704l-320-320v320l-352-352z"],
  },
  {
    name: "Location",
    paths: [
      "M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 516c-108.248 0-196-87.752-196-196s87.752-196 196-196 196 87.752 196 196-87.752 196-196 196zM388 320c0-68.483 55.517-124 124-124s124 55.517 124 124c0 68.483-55.517 124-124 124s-124-55.517-124-124z",
    ],
  },
  {
    name: "HandUp",
    paths: [
      "M731.429 914.286c0-20-16.571-36.571-36.571-36.571s-36.571 16.571-36.571 36.571 16.571 36.571 36.571 36.571 36.571-16.571 36.571-36.571zM804.571 477.714c0-64.571-26.286-108-95.429-108-10.857 0-21.714 1.143-32 2.857-13.143-24-45.714-37.143-72-37.143-13.714 0-27.429 3.429-39.429 10.286-18.286-19.429-41.143-30.286-68-30.286-18.286 0-45.143 8-58.857 20v-189.143c0-39.429-33.714-73.143-73.143-73.143-38.857 0-73.143 34.857-73.143 73.143v329.143c-36 0-73.143-54.857-146.286-54.857-54.857 0-73.143 42.857-73.143 91.429 0 16 66.286 45.143 79.429 51.429 12.571 6.857 25.143 13.714 37.143 21.143 30.286 18.857 56.571 40.571 82.857 64 41.714 36.571 93.143 77.143 93.143 137.714v18.286h365.714v-18.286c0-100 73.143-197.714 73.143-308.571zM877.714 474.857c0 64-17.714 124.571-39.429 184-12.571 34.857-33.714 91.429-33.714 127.429v164.571c0 40.571-32.571 73.143-73.143 73.143h-365.714c-40.571 0-73.143-32.571-73.143-73.143v-164.571c0-27.429-49.143-66.286-68-82.857-23.429-20.571-46.857-40-73.714-57.143-53.143-33.143-150.857-57.714-150.857-134.286 0-90.857 49.714-164.571 146.286-164.571 25.143 0 49.714 4 73.143 12.571v-213.714c0-78.857 66.857-146.286 145.714-146.286 80 0 146.857 66.286 146.857 146.286v96.571c24 1.714 46.857 9.143 68 21.143 8-1.143 16.571-1.714 24.571-1.714 36.571 0 73.143 12 101.714 34.286 108-1.143 171.429 72.571 171.429 178.286z",
    ],
  },
  {
    name: "Pencil",
    paths: [
      "M0 1024c128-384 463-1024 1024-1024-263 211-384 704-576 704s-192 0-192 0l-192 320h-64z",
    ],
  },
  {
    name: "ArrowUp",
    paths: [
      "M0 512c0 282.77 229.23 512 512 512s512-229.23 512-512-229.23-512-512-512-512 229.23-512 512zM928 512c0 229.75-186.25 416-416 416s-416-186.25-416-416 186.25-416 416-416 416 186.25 416 416z",
      "M706.744 669.256l90.512-90.512-285.256-285.254-285.254 285.256 90.508 90.508 194.746-194.744z",
    ],
  },
  {
    name: "ArrowDown",
    paths: [
      "M1024 512c0-282.77-229.23-512-512-512s-512 229.23-512 512 229.23 512 512 512 512-229.23 512-512zM96 512c0-229.75 186.25-416 416-416s416 186.25 416 416-186.25 416-416 416-416-186.25-416-416z",
      "M317.256 354.744l-90.512 90.512 285.256 285.254 285.254-285.256-90.508-90.508-194.746 194.744z",
    ],
  },
];

export const Icons = ({
  icon,
  width,
  height,
  fill,
  onClick,
  style,
  className,
}) => {
  function getPath(iconName) {
    const icon = IconList.find((icon) => icon.name === iconName);

    if (icon) {
      return icon?.paths.join(" ");
    } else {
      console.warn(`icon ${iconName} does not exist.`);
      return "";
    }
  }

  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      onClick={onClick}
      style={style}
      className={`cursor-pointer ${className}`}
      viewBox="0 0 1024 1024"
    >
      <path d={getPath(icon)}></path>
    </svg>
  );
};
