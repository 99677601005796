import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { Icons } from "../components/Icons";
import classNames from "classnames";

const Navbar = ({handleClick}) => {
  const [Mobile, setMobile] = useState(false);
  return (
    <>
      <nav className="navbar">
        <a href="/">
          <div className="items-center inline-flex gap-x-4 sm:gap-x-2 w-full">
            <span>
              <img
                src="/BJP-02.jpg"
                className="w-[55px] h-[50px] sm:w-[30px] sm:h-[30px]"
                id="text"
                alt="logo"
              />
            </span>
            <h3 class="font-serif  font-bold  text-2xl md:text-xl xmd:text-lg sm:text-base">Ashok Tekam</h3>
          </div>
        </a>
        <ul
          className={classNames( {"nav-links": !Mobile}, {"nav-links-mobile": Mobile})}
          onClick={() => setMobile(false)}
        >
          <Link to="/" className="home">
            <li>Home</li>
          </Link>
          <Link to="/about" className="about">
            <li>About</li>
          </Link>
          <Link to="/gallery" className="services">
            <li>Gallery</li>
          </Link>
          <Link className="home" onClick={()=>handleClick()}>
            <li>contact</li>
          </Link>
        </ul>
        <button className="mobile-menu-icon" onClick={() => setMobile(!Mobile)}>
          {Mobile ? (
            <Icons fill="#000" icon="Cross" width="24px" />
          ) : (
            <Icons fill="#000" icon="Hamburger" width="24px" />
          )}
        </button>
      </nav>
    </>
  );
};
export default Navbar;
