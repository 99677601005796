import classNames from "classnames";
import React from "react";
import { pageCss, sMedia } from "./Constant";
import { sFrame } from "./ImageFrame";
import { Icons } from "./Icons";

const PhotoGallery = () => {
  return (
    <>
      <div
        className={classNames(
          "SocialMedia pb-8 bg-[#f1f1f1]",
          pageCss.container
        )}
      >
        <section className={classNames("h-full event flex-col flex-auto")}>
          <div className={pageCss.header}>
            <h2 className={classNames("!text-black", pageCss.heading)}>
              Stay Connected
            </h2>
          </div>
          <div className={classNames('w-full inline-flex justify-around mt-4 gap-x-20 sm:gap-x-6 h-80% sm:gap-y-4 sm:w-full xxmd:flex-col overflow-y-scroll', )}>
            {sMedia?.map((item, index) => (
              <div
                className=" w-full rounded inline-flex sm:flex  justify-center mt-8 sm:mt-4 gap-x-20 sm:gap-x-6 h-80% sm:gap-y-4 sm:w-full"
                key={index}
              >
                <div className="bg-white border rounded-sm border-white p-4 w-full pl-5p sm:pl-4">
                  <div className="icons mb-4 flex gap-x-4 border-b border-dotted border-red-600 pb-3 items-center">
                    <span>{item?.icon}</span>
                    <span className="text-[22px] font-semibold text-[#3b5998]">
                      {item.heading}
                    </span>
                  </div>
                  <div className="flex justify-center ">
                    <img
                      src={item?.img}
                      className="rounded border border-solid self-center w-auto border-black p-4 object-fill"
                      alt={item?.img || "imageloading..."}
                    />
                  </div>
                  <a
                    href={item?.href}
                    className="h-4 my-4 w-full cursor-pointer  items-center gap-x-1 line leading-6 justify-end pt-4 flex "
                    target="_blank"
                  >
                    <p className="underline text-[#35aaf2] text-[15px] xxmd:text-sm sm:text-[6px] text-base">
                      {item?.aTag}
                    </p>
                    <Icons icon="HandUp" width="1em" fill="#666" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};
export default PhotoGallery;
