import classNames from "classnames";
import React, { useState } from "react";
import { GalleryContent, bgColor, pageCss } from "../components/Constant";
import { Icons } from "../components/Icons";

const Gallery = () => {
  const [btnClickStates, setBtnClickStates] = useState({});

  const ChildImage = ({ item }) => {
    const images = [];
    for (let i = 1; i <= item.size; i++) {
      images.push(
        <div className="px-4 flex pb-10 sm:px-0 sm:pb-4" key={i}>
          <img
            src={`${item.defaultPath}${i}.JPG`}
            alt={i || "image loading..."}
            className="rounded h-[270px] min-w-[405px] max-w-full shadow-xl object-cover"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = `${item.defaultPath}${i}.jpg`;
            }}
          />
        </div>
      );
    }

    return <>{images}</>;
  };

  const ImageFragment = ({ item, index, isChild = false }) => {
    return (
      <>
        <h2
          className={classNames("text-xl mt-4 mb-8 font-bold", {
            "text-lg text-gray-900": isChild,
          })}
        >
          {isChild ? `> ${item.subHeading}` : `${index + 1}: ${item.Heading}`}
        </h2>
        <div className="flex h-1/5 overflow-x-auto w-full  sm:gap-x-2 sm:rounded">
          <ChildImage item={item} />
        </div>
      </>
    );
  };

  const toggleBtnClick = (index) => {
    setBtnClickStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className={classNames(" bg-[#f6f2f2] h-fit", pageCss.container)}>
      <section className={classNames("h-fit", pageCss.section)}>
        <div className={pageCss.header}>
          <h2 className={classNames("!text-[#c21e56]", pageCss.heading)}>
            Photo Gallery
          </h2>
        </div>
        <div
          className={classNames(
            " flex-col max-w-[95%] about flex flex-auto gap-x-4 h-full mt-8 sm:mt-6 sm:max-w-full"
          )}
        >
          {GalleryContent.map((item, index) => (
            <div
              className={`mx-8 w-full sm:w-auto sm:mx-2 sm:my-4 p-2 rounded bg-[${
                bgColor[index % 2]
              }]`}
              key={index}
            >
              <ImageFragment item={item} index={index} />
              {item?.children?.length ? (
                <div
                  className={classNames(
                    "flex flex-col gap-x-1 ml-4 mt-2 text-[12px] sm:ml-1"
                  )}
                >
                  <div
                    className={classNames(
                      "flex justify-between items-center cursor-pointer",
                      { hidden: btnClickStates[index] }
                    )}
                    onClick={() => toggleBtnClick(index)}
                  >
                    <span className={classNames(pageCss.btn)}>
                      {" "}
                      कुछ और संबंधित कार्यक्रम
                    </span>
                    <span
                      className={classNames(
                        "flex items-center gap-x-2",
                        pageCss.btn
                      )}
                    >
                      {" "}
                      Show more
                      <Icons icon="ArrowDown" fill="black" width="1em" />
                    </span>
                  </div>

                  {btnClickStates[index] &&
                    item.children.map((x, indey) => (
                      <ImageFragment
                        item={x}
                        index={indey}
                        isChild={true}
                        key={indey}
                      />
                    ))}

                  <div
                    className="cursor-pointer items-center flex justify-end "
                    onClick={() => toggleBtnClick(index)}
                  >
                    <span
                      className={classNames(
                        "cursor-pointer items-center flex gap-x-2",
                        pageCss.btn,
                        { hidden: !btnClickStates[index] },
                        { block: btnClickStates[index] }
                      )}
                    >
                      Show less
                      <Icons icon="ArrowUp" fill="black" width="1em" />
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
export default Gallery;
