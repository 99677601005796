import React, {useRef} from "react";
import Navbar from "./pages/navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Footer from "./components/Footer";
function App() {
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      const element = bottomRef.current;
      const targetPosition = element.getBoundingClientRect().top + window.scrollY;
      const startPosition = window.scrollY;
      const duration = 2000; // Adjust this value to control the speed (in milliseconds)

      const startTime = performance.now();

      const scroll = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easing = (t) => t * t * t; // Cubic easing function

        window.scrollTo(0, startPosition + (targetPosition - startPosition) * easing(progress));

        if (timeElapsed < duration) {
          requestAnimationFrame(scroll);
        }
      };

      requestAnimationFrame(scroll);
    }
  };

  return (
    <div className="mx-20px relative">
      <Navbar handleClick={scrollToBottom}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Footer Ref={bottomRef} />
    </div>
  );
}

export default App;